import request from '@/utils/request'

export function fetchDrive(typ, query) {
  return request({
    url: '/v1/drives/' + typ,
    method: 'get',
    params: query
  })
}

export function fetchFiles(typ, query) {
  return request({
    url: '/v1/drives/' + typ + '/files',
    method: 'get',
    params: query
  })
}
