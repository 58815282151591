<template>
  <div class="analysis-image-data">
    <div>
      <ai-breadcrumb />
      <div class="new-button">
        <guide class="guide-button" />
      </div>
    </div>
    <div class="toolbar">
      <el-form class="search-form" :inline="true" size="small">
        <el-form-item
          class="search-form-gutter"
          :label="$t('analysis.platform')"
        >
          <el-select style="width: 108px" v-model="platform" filterable>
            <el-option
              v-for="item in platformList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('common.brand')">
          <el-select style="width: 128px" v-model="currentBrandId" filterable>
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('analysis.date')">
          <el-select style="width: 108px" v-model="currentDate" filterable>
            <el-option
              v-for="item in fileList"
              :key="item.date"
              :label="item.linkDate"
              :value="item.date"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('common.keyword')">
          <el-input style="width: 138px" v-model="keyword" />
        </el-form-item>
        <el-form-item class="search-form-gutter">
          <el-button
            :disabled="!currentBrandId || !currentDate"
            size="small"
            type="primary"
            @click="clickSearch"
            icon="el-icon-search"
            >{{ $t("common.search") }}</el-button
          >
        </el-form-item>
      </el-form>
      <div class="download-btn">
        <el-button
          :disabled="tableData.length === 0"
          size="small"
          class="excel-dl-btn"
          icon="el-icon-download"
          @click="downloadAll"
          >{{ $t("analysis.downloadAll") }}</el-button
        >
      </div>
    </div>
    <ul
      class="infinite-list"
      v-infinite-scroll="fetchData"
      :infinite-scroll-disabled="disabled"
      style="overflow: auto"
    >
      <el-col
        class="img-col"
        :xs="24"
        :sm="12"
        :md="8"
        :lg="6"
        :xl="4"
        v-for="item in tableData"
        :key="item.url"
      >
        <el-image
          :src="item.url"
          :preview-src-list="[item.url]"
          fit="contain"
        ></el-image>
        <div class="img-ext">
          <el-button
            type="text"
            icon="el-icon-download"
            size="small"
            @click="download(item.url)"
            >{{ $t("common.download") }}</el-button
          >
        </div>
      </el-col>
      <!-- <li v-for="item in tableData" class="infinite-list-item" :key="item.key">
        <el-col class="file-title">{{ item.url | filename() }}</el-col>
        <el-col>
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            item.updatedAt | displayTime()
          }}</span>
        </el-col>
        <el-col class="download-btn">
          <el-button
            type="primary"
            icon="el-icon-download"
            circle
            size="small"
            @click="download(item.url)"
          ></el-button>
        </el-col>
      </li> -->
      <p v-if="loading">{{ $t("common.loading") }}...</p>
      <p v-if="noMore">{{ $t("common.noMoreData") }}</p>
    </ul>
    <el-dialog
      :title="$t('common.downloading')"
      width="40%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible.sync="dialogVisible"
      :before-close="downloadClick"
    >
      <p>
        {{
          isDownloading
            ? $t("analysis.downloadImages", {
                finishCount,
                totalCount: tableData.length,
              })
            : $t("analysis.finishDownload")
        }}
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="downloadClick">{{
          isDownloading ? $t("common.abort") : $t("common.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AiBreadcrumb from "@/components/AiBreadcrumb";
import { fetchBrand } from "@/api/brand";
import { fetchDrive, fetchFiles } from "@/api/drive";
import { downloadFile } from "@/utils/download-file";
import Guide from "@/components/Guide";

export default {
  components: {
    AiBreadcrumb,
    Guide,
  },
  data() {
    return {
      loading: false,
      platform: "tmall",
      platformList: [
        { key: "tmall", value: "TMall" },
        { key: "tiktok", value: "Tiktok" },
      ],
      currentBrandId: null,
      currentDate: null,
      keyword: "",
      tableData: [],
      cursor: "",
      brandList: [],
      fileList: [],
      finishCount: 0,
      dialogVisible: false,
      isDownloading: false,
      noMore: false,
    };
  },
  created() {
    this.fetchBrandList(true);
    this.fetchFileList(true);
  },
  methods: {
    clickSearch() {
      this.cursor = "";
      this.tableData = [];
      this.noMore = false;
      this.fetchData();
    },
    fetchData(cb) {
      if (!this.currentBrandId || !this.currentDate) {
        return;
      }
      let query = { q: this.keyword, cursor: this.cursor };
      if (cb) {
        query = { limit: -1 };
      }
      this.loading = true;
      query.brandId = this.currentBrandId;
      query.date = this.currentDate;
      fetchFiles("images", query)
        .then((response) => {
          if (response.success) {
            const items = response.result.items || [];
            if (cb) {
              cb(items);
            } else {
              if (items && items.length > 0) {
                this.tableData.push(...items);
                this.cursor = items[items.length - 1].key;
              } else {
                this.noMore = true;
              }
            }
          } else {
            this.noMore = true;
          }
          this.loading = false;
        })
        .catch(() => {
          this.noMore = true;
          this.loading = false;
        });
    },
    fetchBrandList(first) {
      this.currentBrandId = null;
      fetchBrand({ platform: this.platform, subjects: "Image" }).then(
        (response) => {
          if (response.success) {
            this.brandList = response.result.items || [];
            if (this.brandList && this.brandList.length > 0) {
              this.currentBrandId = this.brandList[0].id;
            }
            if (first) {
              this.clickSearch();
            }
          }
        }
      );
    },
    fetchFileList(first) {
      this.currentDate = null;
      fetchDrive("images", { platform: this.platform, limit: -1 }).then(
        (response) => {
          if (response.success) {
            this.fileList = response.result.items || [];
            if (this.fileList && this.fileList.length > 0) {
              this.currentDate = this.fileList[0].date;
            }
            if (first) {
              this.clickSearch();
            }
          }
        }
      );
    },
    download(path) {
      downloadFile(path, path ? path.substring(path.lastIndexOf("/") + 1) : "");
    },
    downloadAll() {
      this.fetchData((res) => {
        this.isDownloading = true;
        this.dialogVisible = true;
        const doDownload = (i) => {
          if (this.isDownloading && res.length > i) {
            this.finishCount = i;
            const url = res[i].url;
            downloadFile(
              url,
              url ? url.substring(url.lastIndexOf("/") + 1) : "",
              () => {
                doDownload(i + 1);
              }
            );
          } else {
            this.finishCount = 0;
            this.isDownloading = false;
          }
        };
        doDownload(0);
      });
    },
    downloadClick() {
      this.isDownloading = false;
      this.dialogVisible = false;
      this.finishCount = 0;
    },
  },
  filters: {
    filename(url) {
      return url ? url.substring(url.lastIndexOf("/") + 1) : "";
    },
  },
  watch: {
    platform() {
      this.cursor = "";
      this.tableData = [];
      this.noMore = false;
      this.fetchBrandList();
      this.fetchFileList();
    },
  },
  computed: {
    disabled() {
      return this.loading || this.noMore;
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-image-data {
  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;
  }

  .toolbar {
    background-color: #fff;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    text-align: right;

    .search-form {
      float: left;
    }

    .download-btn {
      display: inline-block;
    }
  }

  .img-col {
    padding: 10px;

    .img-ext {
      height: 30px;
      top: -30px;
      position: relative;
      background-color: black;
      opacity: 0.8;

      .el-button {
        height: 30px;
        color: white;
      }
    }
  }
  .el-row {
    margin: 10px 0;
  }
  .infinite-list {
    text-align: center;
    font-size: 14px;
    color: #606266;
    height: calc(100vh - 50px - 52.5px - 62.5px - 50px - 10px);
    padding: 0;
    margin: 0;
    list-style: none;
    .infinite-list-item {
      padding-left: 10px;
      padding-right: 40px;
      display: flex;
      align-items: center;
      height: 50px;
      background: #fff;
      border-bottom: 1px solid #ebeef5;
      justify-content: space-between;
      .file-title {
        text-align: left;
      }
      .download-btn {
        text-align: right;
      }
    }
  }
}
</style>